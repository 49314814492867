<template>
    <div id="home" class="page-wrapper page-home">
        <!-- Hero Quartier -->
        <section id="hero-quartier" class="hero-quartier hero-transition-group">
            <div class="inner site-max-width">
                <div class="wrap">
                    <img class="spacer" :src="contentIsLoaded ? content.heroQuartier.image.url : ''" :alt="contentIsLoaded ? content.heroQuartier.image.titre : ''" />
                    <div class="content">
                        <h3 class="top-label label" data-inview="fadeInUp" data-delay="200">
                            {{ contentIsLoaded ? content.heroQuartier.surtitre : '' }}
                        </h3>
                        <h1 class="big-title title" data-inview="fadeInUp" data-delay="1000">
                            <span v-html="contentIsLoaded ? content.heroQuartier.titre : '&nbsp;'"></span>
                        </h1>
                        <a
                            href="#arrondissement"
                            class="scroll-down"
                            data-inview="fadeInUp"
                            data-delay="1200"
                            @click.prevent="smoothScrollTo('#arrondissement')"
                        >
                            <svg
                                height="65"
                                viewBox="0 0 43 89"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M20.5739 85.5268L20.5739 -1.96055e-06L22.4261 -1.79863e-06L22.4261 85.5268L41.7031 65.9109L43 67.2244L21.5 89L-5.72571e-06 67.2244L1.2046 65.9109L20.5739 85.5268Z"
                                    fill="black"
                                />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <div class="img-wrap" data-inview="revealLeft" data-delay="200">
                <img class="main img-right" :src="contentIsLoaded ? content.heroQuartier.image.url : ''" :alt="contentIsLoaded ? content.heroQuartier.image.titre : ''" />
            </div>
        </section>

        <!-- Horizontal scroll -->
        <section id="arrondissement" class="horizontal-scroll" horizontal-scroll>
            <div class="rail" horizontal-scroll-rail v-if="contentIsLoaded">
                <div class="zone" :class="{ 'reverse': i % 2 }" v-for="(item, i) in content.imagesEtTextesDefillants" :key="i">
                    <div class="left">
                        <img :src="item.image1.url" :alt="item.image1.titre" />
                    </div>
                    <div class="right">
                        <div class="img-wrap">
                            <img :src="item.image2.url" :alt="item.image2.titre" />
                        </div>
                        <div class="content">
                            <h2 class="regular-title title sm-size">{{ item.titre }}</h2>
                            <p class="regular-text sm-size">
                                <span v-html="item.texte"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Mobile Slider -->
        <section class="mobile-slider site-max-width hero-transition-group">
            <div class="inner">
                <div class="carousel-mobile" v-if="contentIsLoaded">
                    <div class="carousel-cell" v-for="(item, i) in content.imagesEtTextesDefillants" :key="i">
                        <div class="top">
                            <svg
                                class="btn btn-left"
                                @click.prevent="sliderGoToPrev"
                                height="14"
                                viewBox="0 0 21 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.49499 1.43314L2.21039 6.64155L20.2 6.64155L20.3 6.64155L20.3 6.74155L20.3 7.25845L20.3 7.35845L20.2 7.35845L2.20956 7.35845L7.49516 12.5928L7.56961 12.6665L7.49249 12.7374L7.127 13.0736L7.05676 13.1382L6.98894 13.0711L0.929638 7.07106L0.857878 7L0.929638 6.92894L6.98894 0.928942L7.05931 0.859269L7.12967 0.928942L7.49516 1.29086L7.56709 1.36208L7.49499 1.43314Z"
                                    fill="black"
                                    stroke="black"
                                    stroke-width="0.2"
                                />
                            </svg>
                            <h2 class="regular-title title">{{ item.titre }}</h2>
                            <svg
                                class="btn btn-right"
                                @click.prevent="sliderGoToNext"
                                height="14"
                                viewBox="0 0 21 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.7052 1.43314L18.9898 6.64155L1.0002 6.64155L0.9002 6.64155L0.9002 6.74155L0.9002 7.25845L0.9002 7.35845L1.0002 7.35845L18.9906 7.35845L13.705 12.5928L13.6306 12.6665L13.7077 12.7374L14.0732 13.0736L14.1434 13.1382L14.2113 13.0711L20.2706 7.07106L20.3423 7L20.2706 6.92894L14.2113 0.928942L14.1409 0.859269L14.0705 0.928942L13.705 1.29086L13.6331 1.36208L13.7052 1.43314Z"
                                    fill="black"
                                    stroke="black"
                                    stroke-width="0.2"
                                />
                            </svg>
                        </div>
                        <div class="row">
                            <div class="img-wrap">
                                <img :src="item.image2.url" :alt="item.image2.titre" />
                            </div>
                            <div class="content">
                                <p class="regular-text">
                                    <span v-html="item.texte"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Map Quartier -->
        <section id="map-quartier" class="map-quartier site-max-width">
            <div class="inner">
                <h2 class="regular-title" data-inview="fadeInUp" data-delay="200">
                    <span v-html="contentIsLoaded ? content.titreSimple : '&nbsp;'"></span>
                </h2>
                <nav class="map-nav" data-inview="fadeInUp" data-delay="400" v-if="globalsIsLoaded">
                    <a
                        href="#"
                        class="map-link"
                        :class="{ 'is-active': mapStates.includes('Transports') }"
                        @click.prevent="activateMap('Transports')"
                        title="Transports"
                    >
                        Transports
                    </a>
                    <a
                        href="#"
                        class="map-link"
                        :class="{ 'is-active': mapStates.includes(item.title) }"
                        @click.prevent="activateMap(item.title)"
                        :title="item.title"
                        v-for="item in globals.categoriesQuartierMap.data" :key="item.id"
                    >
                        {{ item.title }}
                    </a>
                </nav>
                <div class="map-zone" v-if="globalsIsLoaded">
                    <div class="map-wrap" data-inview="fadeInUp" data-delay="600">
                        <img src="@/assets/img/map-bg.jpg" alt="Carte de quartier - Rosemont" />

                        <div class="zones">
                            <div
                                class="zone"
                                data-zone="Transports"
                                :class="{ 'is-active': mapStates.includes('Transports') }"
                            >
                                <span class="dot svg pos1">
                                    <svg
                                        height="25"
                                        viewBox="0 0 50 50"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M25.0305 45.0615C36.2381 45.0615 45.3237 35.9759 45.3237 24.7683C45.3237 13.5607 36.2381 4.4751 25.0305 4.4751C13.8229 4.4751 4.7373 13.5607 4.7373 24.7683C4.7373 35.9759 13.8229 45.0615 25.0305 45.0615Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M50 25C50 20.0555 48.5338 15.222 45.7867 11.1108C43.0397 6.99952 39.1352 3.79521 34.5671 1.90302C29.9989 0.010832 24.9723 -0.484251 20.1227 0.480379C15.2732 1.44501 10.8187 3.82603 7.32234 7.32234C3.82603 10.8187 1.44501 15.2732 0.480379 20.1227C-0.484251 24.9723 0.010832 29.9989 1.90302 34.5671C3.79521 39.1352 6.99952 43.0397 11.1108 45.7867C15.222 48.5338 20.0555 50 25 50C31.6304 50 37.9893 47.3661 42.6777 42.6777C47.3661 37.9893 50 31.6304 50 25ZM43.8272 25C43.8242 28.5591 42.8083 32.0439 40.8982 35.0471C38.988 38.0502 36.2626 40.4477 33.0404 41.9593C29.8182 43.4709 26.2323 44.0341 22.7019 43.5833C19.1714 43.1324 15.8422 41.686 13.1033 39.4131C10.3644 37.1402 8.32903 34.1347 7.23498 30.7479C6.14094 27.3611 6.03344 23.7328 6.92506 20.2872C7.81668 16.8415 9.67058 13.7208 12.2701 11.2898C14.8696 8.85872 18.1074 7.21775 21.6049 6.55865V29.3981L12.3457 19.9691L7.43828 24.9846L25 42.716L42.6389 25.0772L37.5154 19.9691L28.2562 29.2284V6.54322C32.5973 7.30564 36.5305 9.57419 39.3644 12.9499C42.1982 16.3257 43.7511 20.5925 43.75 25H43.8272Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                <span class="dot svg pos2">
                                    <svg
                                        height="26"
                                        viewBox="0 0 50 51"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M24.5372 49.1188C38.0887 49.1188 49.0743 38.1331 49.0743 24.5816C49.0743 11.0301 38.0887 0.0444336 24.5372 0.0444336C10.9857 0.0444336 0 11.0301 0 24.5816C0 38.1331 10.9857 49.1188 24.5372 49.1188Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M19.46 33.0231H19.676H30.9878C31.2557 33.0342 31.5226 32.9844 31.7685 32.8774C32.0144 32.7705 32.2329 32.6093 32.4076 32.4058L34.5526 29.6589C34.6963 29.5134 34.7913 29.3269 34.8245 29.1251C34.8577 28.9233 34.8274 28.7162 34.7378 28.5323C34.653 28.3355 34.5099 28.1693 34.3278 28.0562C34.1457 27.9431 33.9334 27.8884 33.7193 27.8996H23.7501C23.6622 27.9015 23.5756 27.9216 23.4958 27.9588C23.4161 27.996 23.3451 28.0495 23.2871 28.1157C22.0063 29.7206 20.7563 31.341 19.46 33.0231Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M0.524374 22.0199H18.8424C19.0141 22.0322 19.1861 21.9987 19.3406 21.9228C19.4952 21.8469 19.6268 21.7313 19.722 21.5878L32.6079 5.10623L35.0307 2.09695C31.4562 0.533279 27.5655 -0.172616 23.6695 0.0356782C19.7735 0.243973 15.9802 1.36068 12.5927 3.29657C9.20533 5.23245 6.31768 7.93384 4.16056 11.1849C2.00344 14.4359 0.636656 18.1464 0.169434 22.0199H0.524374Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M40.895 5.70801C36.8363 10.8006 31.7591 17.2513 28.0399 22.0198H40.3857C41.1842 21.9772 41.9769 22.1768 42.6599 22.5927C43.3429 23.0085 43.8842 23.6211 44.2129 24.3501C44.5527 25.0461 44.677 25.8277 44.5698 26.5948C44.4626 27.3619 44.1287 28.0795 43.611 28.6557C41.8209 30.9396 40.1233 33.2082 38.2715 35.4921C37.5317 36.454 36.615 37.2658 35.5709 37.8841C34.3671 38.6466 32.9679 39.0433 31.5431 39.0261C28.8579 39.0261 26.1572 39.0261 23.4566 39.0261C20.1078 39.0261 16.7436 39.0261 13.3948 39.0261C12.7185 39.0926 12.0378 38.9546 11.4407 38.6299C10.8437 38.3053 10.3579 37.8089 10.046 37.2051C9.71745 36.5987 9.5841 35.9057 9.6642 35.2207C9.7443 34.5357 10.0339 33.8921 10.4936 33.3779L14.4288 28.3624L14.7374 27.9149H0.169434C0.6456 31.9769 2.11102 35.86 4.4372 39.2238C6.76338 42.5876 9.87929 45.3293 13.5117 47.2086C17.1441 49.0879 21.1821 50.0473 25.2717 50.0028C29.3612 49.9583 33.3773 48.9112 36.968 46.9533C40.5586 44.9954 43.6141 42.1864 45.8665 38.7728C48.1189 35.3592 49.4995 31.4451 49.8871 27.3737C50.2748 23.3024 49.6576 19.1981 48.0899 15.4207C46.5221 11.6434 44.0516 8.30834 40.895 5.70801Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <div
                                class="zone"
                                :data-zone="item.title"
                                :class="{ 'is-active': mapStates.includes(item.title) }"
                                v-for="(item, i) in globals.categoriesQuartierMap.data" :key="i"
                            >
                                <span :class="'dot pos' + point.pos" v-for="(point, j) in mapPointsCategorized[item.title]" :key="j"><span class="circle">{{ point.nb }}</span></span>
                            </div>
                        </div>
                    </div>
                    <aside class="map-legend" data-inview="fadeInUp" data-delay="800">
                        <div
                            class="zone"
                            data-zone="Transports"
                            :class="{ 'is-active': mapStates.includes('Transports') }"
                        >
                            <span class="dot pos1">
                                <span>
                                    <svg
                                        height="25"
                                        viewBox="0 0 50 50"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M25.0305 45.0615C36.2381 45.0615 45.3237 35.9759 45.3237 24.7683C45.3237 13.5607 36.2381 4.4751 25.0305 4.4751C13.8229 4.4751 4.7373 13.5607 4.7373 24.7683C4.7373 35.9759 13.8229 45.0615 25.0305 45.0615Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M50 25C50 20.0555 48.5338 15.222 45.7867 11.1108C43.0397 6.99952 39.1352 3.79521 34.5671 1.90302C29.9989 0.010832 24.9723 -0.484251 20.1227 0.480379C15.2732 1.44501 10.8187 3.82603 7.32234 7.32234C3.82603 10.8187 1.44501 15.2732 0.480379 20.1227C-0.484251 24.9723 0.010832 29.9989 1.90302 34.5671C3.79521 39.1352 6.99952 43.0397 11.1108 45.7867C15.222 48.5338 20.0555 50 25 50C31.6304 50 37.9893 47.3661 42.6777 42.6777C47.3661 37.9893 50 31.6304 50 25ZM43.8272 25C43.8242 28.5591 42.8083 32.0439 40.8982 35.0471C38.988 38.0502 36.2626 40.4477 33.0404 41.9593C29.8182 43.4709 26.2323 44.0341 22.7019 43.5833C19.1714 43.1324 15.8422 41.686 13.1033 39.4131C10.3644 37.1402 8.32903 34.1347 7.23498 30.7479C6.14094 27.3611 6.03344 23.7328 6.92506 20.2872C7.81668 16.8415 9.67058 13.7208 12.2701 11.2898C14.8696 8.85872 18.1074 7.21775 21.6049 6.55865V29.3981L12.3457 19.9691L7.43828 24.9846L25 42.716L42.6389 25.0772L37.5154 19.9691L28.2562 29.2284V6.54322C32.5973 7.30564 36.5305 9.57419 39.3644 12.9499C42.1982 16.3257 43.7511 20.5925 43.75 25H43.8272Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {{ $t('Stations Bixi') }}
                            </span>
                            <span class="dot pos2">
                                <span>
                                    <svg
                                        height="26"
                                        viewBox="0 0 50 51"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M24.5372 49.1188C38.0887 49.1188 49.0743 38.1331 49.0743 24.5816C49.0743 11.0301 38.0887 0.0444336 24.5372 0.0444336C10.9857 0.0444336 0 11.0301 0 24.5816C0 38.1331 10.9857 49.1188 24.5372 49.1188Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M19.46 33.0231H19.676H30.9878C31.2557 33.0342 31.5226 32.9844 31.7685 32.8774C32.0144 32.7705 32.2329 32.6093 32.4076 32.4058L34.5526 29.6589C34.6963 29.5134 34.7913 29.3269 34.8245 29.1251C34.8577 28.9233 34.8274 28.7162 34.7378 28.5323C34.653 28.3355 34.5099 28.1693 34.3278 28.0562C34.1457 27.9431 33.9334 27.8884 33.7193 27.8996H23.7501C23.6622 27.9015 23.5756 27.9216 23.4958 27.9588C23.4161 27.996 23.3451 28.0495 23.2871 28.1157C22.0063 29.7206 20.7563 31.341 19.46 33.0231Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M0.524374 22.0199H18.8424C19.0141 22.0322 19.1861 21.9987 19.3406 21.9228C19.4952 21.8469 19.6268 21.7313 19.722 21.5878L32.6079 5.10623L35.0307 2.09695C31.4562 0.533279 27.5655 -0.172616 23.6695 0.0356782C19.7735 0.243973 15.9802 1.36068 12.5927 3.29657C9.20533 5.23245 6.31768 7.93384 4.16056 11.1849C2.00344 14.4359 0.636656 18.1464 0.169434 22.0199H0.524374Z"
                                            fill="black"
                                        />
                                        <path
                                            d="M40.895 5.70801C36.8363 10.8006 31.7591 17.2513 28.0399 22.0198H40.3857C41.1842 21.9772 41.9769 22.1768 42.6599 22.5927C43.3429 23.0085 43.8842 23.6211 44.2129 24.3501C44.5527 25.0461 44.677 25.8277 44.5698 26.5948C44.4626 27.3619 44.1287 28.0795 43.611 28.6557C41.8209 30.9396 40.1233 33.2082 38.2715 35.4921C37.5317 36.454 36.615 37.2658 35.5709 37.8841C34.3671 38.6466 32.9679 39.0433 31.5431 39.0261C28.8579 39.0261 26.1572 39.0261 23.4566 39.0261C20.1078 39.0261 16.7436 39.0261 13.3948 39.0261C12.7185 39.0926 12.0378 38.9546 11.4407 38.6299C10.8437 38.3053 10.3579 37.8089 10.046 37.2051C9.71745 36.5987 9.5841 35.9057 9.6642 35.2207C9.7443 34.5357 10.0339 33.8921 10.4936 33.3779L14.4288 28.3624L14.7374 27.9149H0.169434C0.6456 31.9769 2.11102 35.86 4.4372 39.2238C6.76338 42.5876 9.87929 45.3293 13.5117 47.2086C17.1441 49.0879 21.1821 50.0473 25.2717 50.0028C29.3612 49.9583 33.3773 48.9112 36.968 46.9533C40.5586 44.9954 43.6141 42.1864 45.8665 38.7728C48.1189 35.3592 49.4995 31.4451 49.8871 27.3737C50.2748 23.3024 49.6576 19.1981 48.0899 15.4207C46.5221 11.6434 44.0516 8.30834 40.895 5.70801Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                                {{ $t("Métro D'Iberville") }}
                            </span>
                            <span class="dot">
                                <span>
                                    <svg
                                        width="25"
                                        viewBox="0 0 29 5"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect width="13" height="5" fill="#8BD483" />
                                        <rect x="16" width="13" height="5" fill="#8BD483" />
                                    </svg>
                                </span>
                                {{ $t('Plusieurs pistes cyclables') }}
                            </span>
                        </div>
                        <div
                            class="zone"
                            :data-zone="item.title"
                            :class="{ 'is-active': mapStates.includes(item.title) }"
                            v-for="(item, i) in globals.categoriesQuartierMap.data" :key="i"
                        >
                            <span :class="'dot pos' + point.nb" v-for="(point, j) in mapPointsCategorized[item.title]" :key="j"
                                ><span class="circle">{{ point.nb }}</span> {{ point.titre }}</span
                            >
                        </div>
                        <div class="zone" data-zone="All">
                            <span class="dot">{{ $t('Piste cyclables') }}</span>
                        </div>
                    </aside>
                </div>
            </div>
        </section>

        <!-- Form -->
        <BottomForm :formLink="globalsIsLoaded ? globals.footer.formulaireInscription.lienUrl[$route.meta.lang] : ''" :formTitle="$t('Découvrez <br /> le projet')" />
    </div>
</template>

<script>
// @ is an alias to /src
import { gsap } from 'gsap'
import Flickity from 'flickity'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import { initInViewAnimations } from '@/plugins/InViewAnimations'

import { mapState, mapGetters, mapActions } from 'vuex'
import BottomForm from '@/components/BottomForm'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

export default {
    name: 'Quartier',

    components: {
        BottomForm,
    },

    data() {
        return {
            mapStates: ['Transports'],
            slider: null,
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        // this.startHomeHeroAnimations()
                        this.slider = new Flickity(document.querySelector('.carousel-mobile'), {
                            cellAlign: 'center',
                            wrapAround: true,
                            lazyLoad: 1,
                            imagesLoaded: true,
                            prevNextButtons: false,
                            pageDots: false,
                        })

                        setTimeout(() => {
                            this.slider.resize()
                        }, 1555)

                        setTimeout(() => {
                            initInViewAnimations()
                            this.initPageAnimations()
                            document.querySelector('#app').classList.add('is-loaded')
                        }, this.initAnimsDelay1)
                    }, 475)

                    setTimeout(() => {
                        document.querySelector('.site-loader').classList.add('is-loaded')
                        document.querySelector('.page-wrapper').classList.add('is-loaded')
                        this.setAnimsDelays(55)
                    }, this.initAnimsDelay2)
                }
            },
            immediate: true,
        },
    },

    computed: {
        mapPointsCategorized() {
            const mapPoints = []
            let counterNb = 1
            let counterPos = 3

            if (!this.globalsIsLoaded) {
                return mapPoints
            }

            this.globals.categoriesQuartierMap.data.forEach(cat => {
                const catTitle = cat.title
                // console.log(catTitle)
                const items = []
                this.globals.mapQuarterPoint.data.forEach(point => {
                    if (point.page.categorie.includes(catTitle)) {
                        items.push({...point, nb: counterNb, pos: counterPos})
                        counterNb++
                        counterPos++
                    }
                })
                // console.log(items)
                mapPoints[catTitle] = [...items]
                // console.log(mapPoints)
            })

            // console.log(mapPoints)

            return mapPoints
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded', 'initAnimsDelay1', 'initAnimsDelay2']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        ...mapActions(['setAnimsDelays']),
        sliderGoToNext() {
            this.slider.next()
        },
        sliderGoToPrev() {
            this.slider.previous()
        },
        activateMap(slug) {
            if (this.mapStates.includes(slug)) {
                this.mapStates = this.mapStates.filter(state => state !== slug)
                return
            }

            this.mapStates.push(slug)
        },
        smoothScrollTo(selector) {
            setTimeout(() => {
                gsap.to(window, { duration: 2, scrollTo: selector })
            }, 175)
        },
        initPageAnimations() {
            gsap.to('[horizontal-scroll-rail]', {
                scrollTrigger: {
                    trigger: '[horizontal-scroll]',
                    start: 'top top',
                    end: () => '+=400%',
                    scrub: 0,
                    pin: true,
                    anticipatePin: 0,
                },
                x: () => {
                    return -(
                        document.querySelector('[horizontal-scroll-rail] .zone').offsetWidth * 2 +
                        200
                    )
                },
                ease: 'none',
            })
        },
    },
}
</script>
